/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
require("prismjs/themes/prism-okaidia.css")

//讓cookies失效
window["ga-disable-G-4T61R4H6E8"] = true
