module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-4T61R4H6E8"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"蟲探理查","short_name":"蟲探理查","start_url":"/","background_color":"#363636","theme_color":"#363636","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"icon_options":{"purpose":"any maskable"},"icons":[{"src":"src/images/favicon/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"src/images/favicon/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/images/favicon/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"src/images/favicon/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/images/favicon/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6b9c04a6379206254151f03cc925dd6f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/","/blog/*","/blog-list/*","/about/","/project/*"]},
    },{
      plugin: require('../node_modules/gatsby-remark-code-buttons/gatsby-browser.js'),
      options: {"plugins":[],"toasterText":"程式碼已複製","toasterDuration":1000},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
